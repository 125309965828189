import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateService } from '@lf/translate-core';
import { spinner } from '@modules/spinner/spinner';
import { SnackBarService } from '@services/snack-bar/snack-bar.service';
import { NamedItem } from '@type/named-item.type';
import { PostRequest, basePostRequest } from '@type/requests.type';
import { ListWithPagination } from '@type/response.type';
import { UserRole, UsersRoleList } from '@type/user-role.type';
import { joinUrls } from '@utils/urls';
import { Observable, tap } from 'rxjs';

@Injectable()
export class UserRolesService {
  private http = inject(HttpClient);
  private snack = inject(SnackBarService);
  private translate = inject(TranslateService);
  private baseRoleRequest = {
    ...basePostRequest,
    per_page: -1,
    sorting: 'name desc',
  };
  private successSave = 'success_save';

  getUserRolesList(request: PostRequest = this.baseRoleRequest): Observable<
    ListWithPagination<{
      roles: UsersRoleList;
      meta: { possible_role_types: Record<string, string> };
    }>
  > {
    return this.http.post<
      ListWithPagination<{
        roles: UsersRoleList;
        meta: { possible_role_types: Record<string, string> };
      }>
    >(joinUrls(environment.urls.roles, environment.urls.index), request);
  }

  updateUserRole(role: NamedItem): Observable<UserRole> {
    return this.http
      .put<UserRole>(joinUrls(environment.urls.roles, String(role.id)), role)
      .pipe(tap(() => this.successSnackMessage()));
  }

  createUserRole(role: { name: string }): Observable<UserRole> {
    return this.http.post<UserRole>(environment.urls.roles, role).pipe(tap(() => this.successSnackMessage()));
  }

  @spinner({ selectorById: 'settings-spinner-container' })
  deleteUserRole(id: number): Observable<UserRole> {
    return this.http
      .delete<UserRole>(joinUrls(environment.urls.roles, String(id)))
      .pipe(tap(() => this.successSnackMessage()));
  }

  private successSnackMessage() {
    this.snack.show('success', {
      text: `${this.translate.t(this.successSave)}`,
    });
  }
}
